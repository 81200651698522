@import "src/sass/common";

@font-face {
    font-family: 'WorkSansMedium';
    src: url("../assets/fonts/Work_Sans/static/WorkSans-Medium.ttf");
  }

  @font-face {
    font-family: 'WorkSansRegular';
    src: url("../assets/fonts/Work_Sans/static/WorkSans-Regular.ttf");
  }

  @font-face {
    font-family: 'WorkSansSemiBold';
    src: url("../assets/fonts/Work_Sans/static/WorkSans-SemiBold.ttf");
  }

  @font-face {
    font-family: 'WorkSansLight';
    src: url("../assets/fonts/Work_Sans/static/WorkSans-Light.ttf");
  }

@include scrollbars(.5em, #d9d9d9, rgba(0, 0, 0, 0));

html {
    position: relative;
    min-width: 320px;
    overflow-y:scroll;
}

html,
body {
    min-height: 100%;
    min-width: $resMin;
    background-color:#f2f2f2 !important;
}

* {
    font-family: 'WorkSansRegular', serif;
    text-decoration: none;
    padding: 0;
    margin: 0;
}
.innerLink2 a{
    color: white !important;
    text-decoration: underline !important;
}
.innerLink2 a:hover{
    color: white !important;
    text-decoration: underline !important;
    transition: color .2s ease;
}
.innerLink a{
    color: #0014ff !important;
    text-decoration: underline !important;
    word-break: break-word !important;
}
.innerLink a:hover{
    color: #0014ff !important;
    text-decoration: underline !important;
    transition: color .2s ease;
}
.dashboard-body {

    position: absolute;
    width: 100%;
    min-height: 100%;

    a {
        transition: color .2s ease;

        &:hover {
            color: #1E87F0;
        }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        width: 100%;
        margin-top: 0;
    }

    /* Hide AMchart watermark */
    .amcharts-chart-div a {
        display: none !important;
    }

    .amChartsLegend {
        max-height: 230px !important;
        overflow-y: auto !important;

        text {
            text-transform: capitalize;
        }

    }

}

a.sn-link,
.dashboard-body a {
    text-decoration: none !important;
}

p {
    margin-bottom: 12px;
    font-size: 14px;
}

/* Modal */
.modal-backdrop.fade.show {
    opacity: 0.5 !important;
}

.modal.fade.show {
    opacity: 1 !important;
    top: 100px !important;
    z-index: 1055;
}

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;

    .btn-no {
        border-width: 1px;
        color: #000A12;
        background: transparent;
        border-color: #d6d6d6;
        background-color: #BDBDBD !important;
        box-shadow: 0 8px 30px 0 rgba(189, 189, 189, 0.2);
        height: 30px;
        width: 80px;
        border: 0;
        border-radius: 8px;
    }

    .btn-yes {
        border-width: 1px;
        background-color: #333333 !important;
        box-shadow: 0 8px 30px 0 rgba(30, 135, 240, 0.2);
        height: 30px;
        width: 80px;
        border: 0;
        border-radius: 8px;
        color: white;
    }
}

// Toaster
.toast-container .toast {
    padding: 10px !important;
}

.toast-info {
    background-color: #1E87F0;
    background-image: none;
}

.toast-error {
    background-color: #d9534f;
    background-image: none;
}

.toast-title {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

/* Dropdown question */

body .ui-dropdown {
    min-width: 60px;
    background-color: #ffffff !important;
    height: 22px;
}

.ui-dropdown label.ui-dropdown-label.ui-placeholder {
    font-size: 10px;
    background: #cbcbcb;
    border-radius: 20px;
    padding: 2px 7px;
}

.ui-dropdown label.ui-dropdown-label {
    font-size: 10px;
    background: #ffffff;
    color: #828282;
    border-radius: 0;
    height: 20px;
}

.ui-dropdown label.ui-dropdown-label.ui-placeholder+div {
    background-color: #cbcbcb !important;
}

body .ui-dropdown .ui-dropdown-trigger {
    background: #ffffff;
    border-radius: 20px;
    width: 20px;
    height: 20px;
}

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover,
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group:not(.ui-state-highlight):not(.ui-state-disabled):hover {
    color: #262626;
    background-color: #f5f5f5;
}

body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight,
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
    color: #ffffff;
    background-color: #1E87F0;
}

/* Tags */
.bootstrap3-info.ng2-tag-input {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
    height: inherit !important;
}

.ng2-tags-container {
    border: 1px solid #f0f0f0 !important;
    padding: 3px;
}

.ng2-tags-container:focus {
    background-color: #FFF;
    border: 1px solid rgba(30, 135, 240, 0.41);
    box-shadow: inset 0 1px 14px 0 rgba(30, 135, 240, 0.2),
        0 0 14px 0 rgba(30, 135, 240, 0.3);
}


.ng2-tag-input__text-input {
    font-size: 12px !important;
}

.bootstrap3-info {
    height: 20px;
    background: #386BA7 !important;
}

.btn-blue {
    height: 29px;
    width: 130px;
    border: 0px;
    border-radius: 8px;
}

.btn-grey {
    height: 29px;
    width: 130px;
    border: 0px;
    border-radius: 8px;
}

/* Toastr class navbar */

.inline .nav-toast-container {
    display: block;
    width: 600px;
    height: 65px;
    overflow-y: hidden;
}

.nav-toast-container .toast {
    display: inline-block;
    padding: 15px 15px 15px 50px !important;
    margin: 0 1px;
    border-radius: 0;
    width: 195px;
    height: 65px;
    opacity: 0.8;
    background-color: #ff9900 !important;
    color: #ffffff;
    box-shadow: 0 0 12px #999999;
    position: relative;
    background-image: none;

    .toast-title {
        font-weight: bold;
    }
}

.nav-toast-container .toast:before {
    content: "\f086";
    font-family: FontAwesome,sans-serif;
    left: 16px;
    position: absolute;
    top: 24px;
    font-size: 28px;
}

.nav-toast-container .toast:hover {
    opacity: 1;
    box-shadow: none;
}
.nsdicon-angle-down:after {
    font-family: "FontAwesome",sans-serif;
    content: "\f078";
    display: inline-block;
}
.mat-table {
  background: #fff;
}
.mat-header-cell {
  color: rgba(0,0,0,.54);
  font-size: 12px;
  font-weight: 500;
}
.mat-cell, .mat-footer-cell {
  color: rgba(0,0,0,.87);
  font-size: 14px;
}
.mat-card {
  background: #fff;
  color: rgba(0,0,0,.87);
}
mat-footer-row, mat-header-row, mat-row, td.mat-cell, td.mat-footer-cell, th.mat-header-cell {
  border-bottom-color: rgba(0,0,0,.12);
}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
    display:none !important;
    }
    .mat-tab-label-active{
        color:#828282 !important;


        font-family: WorkSansRegular,sans-serif !important;
        font-size: 18px !important;
        line-height: 21px !important;
        }
        .mat-tab-label{
          color:#BDBDBD;
          min-width: 115px !important;
          font-family: WorkSansRegular,sans-serif !important;
          font-size: 18px !important;
          line-height: 21px !important;
          padding:0px 0px !important;
          }
          .mat-tab-header, .mat-tab-nav-bar {
            border-bottom: 0px solid rgba(0,0,0,.12);
            width:310px !important;
        }
        .mat-tab-label-container{
                z-index: 100 !important;
        }

//***********flow builder*********************
.flow-builder-tools .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none !important;
}

.flow-builder-tools .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: #1E87F0 !important;
    display: block !important;
    height: 5px !important;
}
.flow-builder-tools .mat-tab-label-container{
    margin-left:0px !important;
}
.flow-builder-tools .mat-tab-header{
    background:#F5F5F5;
}
.flow-builder-tools .mat-tab-header, .mat-tab-nav-bar {
    border-bottom: 0px solid rgba(0, 0, 0, 0.12);
    width: 268px !important;
}
.flow-builder-status .switch-small small {
    width: 15px !important;
    height: 15px !important;
}
.flow-builder-status .switch-small {
    min-width: 26px !important;
    height: 15px !important;
    border-radius: 20px;
}
.switch.switch-small small {

  right: calc(100% - 15px);
}
.switch.switch-small.checked small {
  right: 0;
  left: auto;
}
.flow-builder-status .agent-switch .switch-small {
    width: 14px !important;
    height: 15px !important;
    border-radius: 20px;
  }
  @media (max-width:770px){
    .dashboard-body{
      width: 85%;
      min-height: 100%;
    }
  }
  @media (max-width:460px){
    .dashboard-body{
      width: 115%;
      min-height: 105%;
    }
  }
  @media (max-width:360px){
    .dashboard-body{
      width: 105%;
      min-height: 105%;
    }
  }
  .rightBar{
    position: fixed;
    right: 0px;
    background: white;
    padding: 0px;
    top: 66px;
    width: 27%;
    height: 100%;

  h4{
    font-family: 'WorkSansRegular', sans-serif;
      font-size:24px;
      font-weight:normal;
      padding: 6% 0% 2% 1%
  }
  .form-body {
        padding: 15px 20px;
        height:80vh;
        overflow-y:scroll;

        .question-form-label {
            font-size: 12px;
            color: #828282;
        }
    }
}
.viewHeading{
  display: block;
  margin: auto;
}
.plusButtons{
  margin-top:32px;
}
.search * {
  outline: none;
  box-sizing: border-box;
}
.search__wrapper {
  position: relative;
}
.search__field {
  width: 100%;
  height: 36px;
  padding-left: 10px;
  font-family: 'WorkSansRegular', serif;
  font-size: 17px;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
}
.search__field:focus {
  border-bottom-color: #ccc;
  color: #2b2b2b;
  cursor: default;
}

.search__icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  width: 35px;
  height: 35px;
  font-size: 17px;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  display: inline-block;
}
.ngSelectDropdown .ng-select .ng-select-container .ng-value-container .ng-placeholder {
    padding-top: 22px;
}
.ngSelectDropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover{
    background-color: #7e7c7c !important;
    color: white;
  }
  .ngSelectDropdown .ng-select .ng-select-container {
  min-height: 30px !important;
  border: 1px solid #3f3e3e;
  position: unset;
  }
  .ngSelectDropdown .ng-select .ng-select-single .ng-select-container {
  border: none;
  }
  .ngSelectDropdown .ng-dropdown-panel.ng-select-bottom {
    top: 40px;
  }
  .ngSelectDropdown .ng-select .ng-select-container .ng-value-container{
  padding: 0px 20px !important;
  }
  .ngSelectDropdown .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #3f3e3e !important;
  box-shadow: none !important;
  border-width: 1px !important;
  }
  .ngSelectDropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected{
  color: black;
  background: white;
  }
  .ngSelectDropdown .ng-select.ng-select-focused .ng-select-container:after{
   border: #3f3e3e !important;
   border-width: 1px !important;
  }
  .ngSelectDropdown .ng-select.ng-select-focused .ng-select-container .ng-arrow-wrapper .ng-arrow{
  color: black !important;
  }
  .ngSelectDropdown .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 500 !important;
  }
  .ngSelectDropdown .ng-select {
    padding-bottom: 0em
  }
  .actionButton{
    ul{
      min-width: -webkit-fill-available;
      max-height: 144px;
      margin-top: -25%;
      border-radius:3px;
      width: 90px;
    }
    em {
    padding-left: 5px;
     }
  }

  .customButtonStyle{
    height: 29px;
    border-radius: 8px;
    width: -webkit-fill-available;
    border: 0px;
  }
  .mat-dialog-container {
    background-color: #fff!important;
    box-shadow: none!important;
    border-radius: 8px!important;
  }

  .visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}

.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption), .visually-hidden:not(caption) {
  position: absolute!important;
 }

.switch {
  background: white;
 }
 .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
 }
 .mat-option {
  color: rgba(0,0,0,.87);
 }
 .mat-autocomplete-panel {
  background: #fff;
  color: rgba(0,0,0,.87);
}
.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
  color: black !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__notch {
  border-width: 1px!important;
  border-color: gray!important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 1px!important;
  border-color: gray!important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline .mdc-notched-outline__leading {
  border-width: 1px!important;
  border-color: gray!important;
}
.mat-mdc-select-value-text, .mdc-list-item__primary-text {
  font-size: 15px !important;
}
.mat-mdc-option {
  min-height: 40px !important;
}
.bs-datepicker.contact-date-picker {
  top: 0px !important;
  left: 0px !important;
}

.audienceTagdropdown .display-text{
  max-width: 200px;
    text-wrap: wrap;
    padding-right: 0px !important;
    line-height: 15px;
    min-width: 100px;
    margin-left: 20px
}
