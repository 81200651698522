@mixin placeholderStyle($color, $opacity) {
  &::-webkit-input-placeholder {
    color: $color;
    opacity: $opacity;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
    opacity: $opacity;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
    opacity: $opacity;
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  ::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  ::-webkit-scrollbar-thumb {
    background: $foreground-color;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

@mixin bg-translucent-dark($opacity) {
  background: rgba(0, 0, 0, $opacity);
}

@mixin main-background() {
  $mainBgUrl: $images-root + 'blur-bg.jpg';

  &::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fffe46;
    background-size: cover;
    will-change: transform;
    z-index: -1;
  }
}

@mixin bg-nr($relativeUrl) {
  background: #ff400b;

}

@mixin bg-image($relativeUrl) {
  background-image: url($images-root + $relativeUrl);
}
